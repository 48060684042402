import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {environment} from '@environments/environment';
import {init, browserTracingIntegration, replayIntegration} from '@sentry/angular';


init({
  dsn: environment.SENTRY_DSN_ANGULAR,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["api.leclosdesmuriers.fr", "https://api.leclosdesmuriers.fr"],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
